
.class-modal-regular {
    width: 52vw;    
    max-width: 52vw;
}

.modal {
    margin-top: 10px;
}

.react-datetime-picker__wrapper {
    border: none;
}

.class-form {
    width: 400px;    
}

.class-form-group {
    margin-top: 12px;    
}

.class-form-group-cancelled {
    margin: 12px 0px 6px;    
}

.classesModalBody {
    padding: 10px 16px 12px;
}

.modalClassesButtons {
    float: right;
}

#mainContainer {    
    float: left;
}

@media screen and (max-width: 768px) {
    .class-modal-regular {
        width: 100vw;    
        max-width: 100vw;
    }
}

@media screen and (min-width: 769px) and (max-width: 1000px) {

    .class-modal-regular {
        width: 75vw;    
        max-width: 75vw;
    }
}