.individualCardDiv {
    width: 30%;
}

.cardDeck {
    display: 'flex';
    flex-direction: 'row';
}

.card {
    flex: 1;
    margin-bottom: 10px !important;
}

.cardEditBtn {
    width: 100px;
    margin: 10px;
}

#editButtonDiv {
    text-align: center;
}

#locationInactive {
    color: rgb(154, 20, 20) !important;
    font-size: small;
}

@media screen and (max-width: 768px) {
    .individualCardDiv {
        width: 90%;
    }
}