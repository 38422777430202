.logoutbtn {  
  float: right;
}

.rbc-allday-cell {
    display: none;
  }
.rbc-time-view .rbc-header {
    border-bottom: none;
}

/* changes when you select a slot */
.rbc-slot-selection {
    z-index: 10;
    position: absolute;
    background-color: #647474;
    color: #d8d8c8;
    font-size: 80%;
    width: 100%;
    padding: 3px;
    text-align: left;
  }
  
  .rbc-slot-selecting {
    cursor: move;
  }
/* 
  .rbc-event {
    border: none;
    box-sizing: border-box;
    box-shadow: none;
    margin: 0;
    padding: 2px 5px;
    background: #647474 !important;
    border-radius: 5px;
    color: #d8d8c8;
    cursor: pointer;
    width: 100%;
    text-align: left;
  } */

  .rbc-event-label {
       font-size: 80%;       
  }