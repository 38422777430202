.greenbackground {
    background-color: #647474;
    color: #d8d8c8;
  }
  
.beigebtn {
  background-color: #d8d8c8;
  color: #647474;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

.beigebtn:active {    
  box-shadow: 0 1px #666;
  transform: translateY(5px);
}