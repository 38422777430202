h1 {
  font-weight: bold;
}

h3 {
  font-weight: bold;
}

h4 {
  font-weight: bold;
}

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.35;
    background-color: #d8d8c8;
  }

.container {
  margin-top: 80px;
  margin-bottom: 25px;
  background-color: whitesmoke
}

.bold_text {
  font-weight: bold;
}

.centered_text{
  text-align: center;
}

#btnwrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.greenbtn {
  background-color: #647474;
  color: #d8d8c8;
  border-radius: 15px;
  box-shadow: 0 5px #999;
}

.greenbtn:active {    
  box-shadow: 0 1px #666;
  transform: translateY(5px);
}

.footer{
  height: 20px;  
  color: #d8d8c8;
  font-size: xx-small;
  /* background-color: #2e3d30;
  background-image: linear-gradient(155deg,black 0%, #b7bbb3 20%,#2e3d30 40%); */
  background-color: #2e3d30;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

#footerfont{
  margin-top: 3px;
}

textarea {
  resize: none;
}

.invisible {
  display: none;
}