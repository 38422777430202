
.individualCardDivList {
    width: 315px;
    text-align: center;
}

.cardDeckList {
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .individualCardDivList {
        width: 98%;
    }
}